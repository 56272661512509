import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Button,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
} from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import CreateModal from "./actions/CreateModal.jsx";
import { useSelector, useDispatch } from "react-redux";
import { getAlerts } from "../../../store/alerts/alertSlice.js";

const Announcements = () => {
  const { t } = useTranslation();
  const [showCreateAlert, setShowCreateAlert] = useState(false);
  const [viewAlert, setViewAlert] = useState(null); // State for the alert to view
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); // State for modal visibility
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts || []); // Ensure data exists
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getAlerts({ page }));
  }, [dispatch, page, showCreateAlert]);

  const handleViewClick = (alert) => {
    setViewAlert(alert);
    setIsViewModalOpen(true);
  };

  return (
    <>
      <SimpleGrid columns={{ sm: 1, md: 2 }} justifyContent="space-between">
        <Flex marginBottom={8}>
          <Button
            bg="red.600"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={18}
            marginInlineEnd={4}
            color="white"
            _hover={{ bg: "red.600" }}
            onClick={() => setShowCreateAlert(true)}
          >
            إرسال تنبيه
          </Button>
        </Flex>
      </SimpleGrid>

      {showCreateAlert && (
        <CreateModal onClose={() => setShowCreateAlert(false)} />
      )}

      {/* Table for displaying alerts */}
      {alerts?.data?.data?.length > 0 && (
        <Table variant="simple" mt={8} dir="rtl">
          <Thead>
            <Tr>
              <Th>الوصف</Th>
              <Th isNumeric>إجمالي المستخدمين</Th>
              <Th isNumeric>المستخدمين الذين قرأوا</Th>
              <Th isNumeric>المستخدمين غير المقروءين</Th>
              <Th>تاريخ الإنشاء</Th>
              <Th>عرض التفاصيل</Th> {/* New column for view button */}
            </Tr>
          </Thead>
          <Tbody>
            {alerts?.data?.data.map((alert) => (
              <Tr key={alert._id}>
                <Td>{alert.alertDetails.description.slice(0, 50)}....</Td>
                <Td isNumeric>{alert.totalUsers}</Td>
                <Td isNumeric>{alert.readUsers}</Td>
                <Td isNumeric>{alert.unreadUsers}</Td>
                <Td>
                  {new Date(alert.alertDetails.created_at).toLocaleDateString(
                    "ar-EG"
                  )}
                </Td>
                <Td>
                  <Button
                    onClick={() => handleViewClick(alert)}
                    variant="link"
                    color="blue.500"
                    fontSize="20px"
                  >
                    <FaEye />
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      {/* View Modal */}
      {viewAlert && (
        <Modal
          isOpen={isViewModalOpen}
          onClose={() => setIsViewModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>تفاصيل التنبيه</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                <strong>الوصف:</strong> {viewAlert.alertDetails.description}
              </Text>
              <Text>
                <strong>الرابط:</strong> {viewAlert.alertDetails.url}
              </Text>
              <Text>
                <strong>فئة التنبيه:</strong> {viewAlert.alertDetails.category}
              </Text>
              <Text>
                <strong>تاريخ الإنشاء:</strong>{" "}
                {new Date(viewAlert.alertDetails.created_at).toLocaleDateString(
                  "ar-EG"
                )}
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                onClick={() => setIsViewModalOpen(false)}
              >
                إغلاق
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default Announcements;
