import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Checkbox,
  CheckboxGroup,
  Stack,
  Flex,
  Switch,
  Text,
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
import * as FiIcons from "react-icons/fi";
import { createAlert } from "../../../../store/alerts/alertSlice";
import { getUsers } from "../../../../store/users/usersSlice";
import theme from "../../../global/theme";
import Pagination from "../../../shared/pagination/Pagination";

const CreateModal = ({ onClose }) => {
  const { t } = useTranslation();
  const users = useSelector((state) => state.users);
  const alerts = useSelector((state) => state.alerts);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [userPage, setUserPage] = useState(1);
  const [userQuery, setUserQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showUsers, setShowUsers] = useState(false); // Switch to show/hide users list
  const [sendEmail, setSendEmail] = useState(false); // Switch to send email with alert

  useEffect(() => {
    dispatch(
      getUsers({
        page: userPage,
        query: userQuery,
        filter: JSON.stringify({ role: "marketer", is_active: true }),
      })
    );
  }, [dispatch, userPage, userQuery]);

  const handleUserChange = (user) => {
    const exists = selectedUsers.find((u) => u._id === user._id);
    if (exists) {
      setSelectedUsers((prev) => prev.filter((u) => u._id !== user._id));
    } else {
      setSelectedUsers((prev) => [
        ...prev,
        { _id: user._id, email: user.email },
      ]);
    }
  };

  const onSubmit = (values) => {
    values.userIds = selectedUsers;
    values.sendEmail = sendEmail; // Send email based on switch value
    dispatch(createAlert(values))
      .unwrap()
      .then(() => {
        onClose();
      });
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            إرسال تنبيه إلي المسوقين
          </ModalHeader>

          <ModalBody>
            {/* Switch to show/hide the user list */}
            <FormControl display="flex" alignItems="center" mb={4}>
              <FormLabel color="white" fontWeight="bold" mb="0">
                إظهار قائمة المستخدمين
              </FormLabel>
              <Switch
                colorScheme="orange"
                onChange={() => setShowUsers(!showUsers)}
              />
            </FormControl>

            {/* Input for user query */}
            {showUsers && (
              <>
                <FormControl>
                  <FormLabel color="white">البحث عن مستخدم</FormLabel>
                  <Input
                    type="text"
                    bg={theme.bg}
                    color={theme.dark}
                    border="none"
                    borderRadius={4}
                    placeholder="اكتب للبحث..."
                    value={userQuery}
                    onChange={(e) => setUserQuery(e.target.value)}
                  />
                </FormControl>

                {/* Checkbox list of users */}
                <FormControl mt={6}>
                  <Box marginInline={5}>
                    <FormLabel fontWeight="bold" color="white" mb={4}>
                      اختر المسوقين
                    </FormLabel>

                    <CheckboxGroup>
                      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={2}>
                        {users?.data?.data?.map((user) => (
                          <Checkbox
                            key={user._id}
                            value={user._id}
                            colorScheme="orange"
                            color={"white"}
                            onChange={() => handleUserChange(user)}
                          >
                            {user.name || user.email}
                          </Checkbox>
                        ))}
                      </SimpleGrid>
                    </CheckboxGroup>
                  </Box>
                  {errors.userIds && (
                    <Text color="red.600" marginTop={2}>
                      {errors.userIds.message}
                    </Text>
                  )}
                </FormControl>

                {/* Pagination for users */}
                <Flex justifyContent="flex-end" mt={4}>
                  <Pagination
                    page={userPage}
                    itemsCount={users.data?.itemsCount ?? 0}
                    onChange={(page) => setUserPage(page)}
                  />
                </Flex>

                {/* Show count of selected users */}
                <Text color="white" mt={4}>
                  {`تم تحديد ${selectedUsers.length} مسوقين`}
                </Text>

                {/* Switch to send email */}
                <FormControl display="flex" alignItems="center" mt={4}>
                  <FormLabel color="white" fontWeight="bold" mb="0">
                    إرسال بريد إلكتروني مع التنبيه
                  </FormLabel>
                  <Switch
                    colorScheme="orange"
                    onChange={() => setSendEmail(!sendEmail)}
                    isDisabled={!showUsers}
                  />
                </FormControl>
              </>
            )}

            {/* Alert message */}
            <FormControl mt={6}>
              <FormLabel fontWeight="bold" color="white">
                رسالة التنبيه
              </FormLabel>
              <Textarea
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                placeholder={"رسالة التنبيه"}
                {...register("description")}
              />
              {errors.description?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.description.message}
                </Text>
              )}
            </FormControl>

            {/* Alert link */}
            <FormControl mt={6}>
              <FormLabel fontWeight="bold" color="white">
                الرابط عند النقر
              </FormLabel>
              <Input
                type="text"
                bg={theme.bg}
                color={theme.dark}
                border="none"
                borderRadius={4}
                {...register("url")}
              />
              {errors.url?.message && (
                <Text color="red.600" marginTop={2}>
                  {errors.url.message}
                </Text>
              )}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                isLoading={alerts.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                marginInlineStart={4}
                isLoading={alerts.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateModal;
