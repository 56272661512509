import { Box, Flex } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateAlert } from "../../../store/alerts/alertSlice";
import { useTheme } from "@chakra-ui/react";

const AlertsList = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  return (
    <Flex
      direction="column"
      bg="white"
      borderRadius="md"
      w="100%"
      boxShadow="md"
      overflowY="auto"
    >
      {data.map((el) => (
        <Box
          key={el._id}
          opacity={el.is_read ? 0.8 : 1}
          cursor="pointer"
          display="flex"
          alignItems="center"
          bg={el.is_read ? theme.colors.bg : "white"}
          borderRadius="md"
          mb={2}
          p={4}
          boxShadow="sm"
          _hover={{
            bg: el.is_read ? theme.colors.bg : "gray.50",
            transform: "scale(1.02)",
            transition: "transform 0.2s ease-in-out",
          }}
          onClick={() => {
            const navigateTo = () => {
              window.location.href = el?.alert?.url;
            };

            if (!el.is_read) {
              dispatch(updateAlert({ _id: el._id, values: { is_read: true } }))
                .unwrap()
                .then(navigateTo);
            } else {
              navigateTo();
            }
          }}
        >
          <Box
            color={!el.is_read && theme.colors.error}
            borderRadius="md"
            flex={1}
            textAlign="right"
            cursor="pointer"
          >
            <Box display="inline-block" me={3}>
              {el?.alert?.description}
            </Box>
          </Box>
        </Box>
      ))}
    </Flex>
  );
};

export default AlertsList;
